<template>
  <div>
    <preloader />

    <!-- Main container -->
    <div class="page-container">
      <navigation />

      <smartheader title="Contact Us" />

      <!-- contact us -->
      <div
        id="contact-us"
        class="bloc l-bloc"
      >
        <div class="container bloc-lg">
          <div class="row">
            <div class="col-sm-6 col-lg-4 col-md-6">
              <div class="card card-space">
                <div class="card-body">
                  <div class="text-lg-left text-left">
                    <span class="ion ion-settings icon-medium-sea-green icon-lg" />
                  </div>
                  <h4 class="mg-md packages">
                    Delegates Support
                  </h4>
                  <p class="package-content">
                    Questions about downloading, logging in or using a Showbay24 app
                  </p><b-link
                    to="/delegates/support/email-us"
                    class="btn btn-d btn-lg btn-block btn-rd account-btn"
                  >
                    Email Us
                  </b-link>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6">
              <div class="card card-space">
                <div class="card-body">
                  <div class="text-lg-left text-left">
                    <span class="icon-lg icon-medium-sea-green ion ion-android-desktop" />
                  </div>
                  <h4 class="mg-md packages">
                    Organiser Support
                  </h4>
                  <p class="package-content">
                    Questions about setting up, updating and configuring your custom event app
                  </p><b-link
                    to="/organiser/support/email-us"
                    class="btn btn-d btn-lg btn-block btn-rd account-btn"
                  >
                    Email Us
                  </b-link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-body card-space">
                  <div class="text-lg-left text-left">
                    <span class="icon-lg icon-medium-sea-green fa fa-hands-helping float-none" />
                  </div>
                  <h4 class="mg-md packages">
                    Contact Sales
                  </h4>
                  <p class="package-content">
                    Ask about our products, pricing, or request <br>a demo
                  </p><b-link
                    to="/request-info"
                    class="btn btn-d btn-lg btn-block btn-rd account-btn"
                  >
                    Request Info
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- contact END -->

      <blank />

      <footerlinks />

      <copyright />

      <scrolltotop />
    </div>
    <!-- Main container END -->
  </div>
</template>

<script>
import preloader from '@/components/blocks/home/preloader.vue'
import navigation from '@/components/layouts/home/navigation.vue'
import smartheader from '@/components/blocks/home/smartheader.vue'
import blank from '@/components/blocks/home/blank.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'ContactUs',
  components: {
    preloader,
    navigation,
    smartheader,
    blank,
    footerlinks,
    copyright,
    scrolltotop
  },
}
</script>

<style scoped>

.account-btn {
  background-color: #0D85A2;
  font-weight: 600;
  color: #fff;
}

.account-btn:hover {
  background-color: #27AE5F;
}
</style>