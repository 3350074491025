<template>
  <div>
    <!-- bloc-0 -->
    <div
      id="bloc-0"
      class="bloc full-width-bloc carousel-slider l-bloc"
    >
      <div class="container bloc-no-padding-lg bloc-no-padding-md bloc-no-padding-sm bloc-no-padding">
        <div class="row voffset-clear-sm nav-bar">
          <div class="col-lg-3 col-9 col-sm-7 col-md-8">
            <b-nav class="navbar navbar-light row navbar-expand-md logo-padding">
              <b-navbar-brand
                href="/"
                class="logo-size"
              >
                <b-img
                  src="/assets/img/logo.png"
                  alt="Showbay24"
                  style="width: 12vw;"
                />
              </b-navbar-brand>
            </b-nav>
          </div>
          <div class="cal-nav-right col-lg-6 col-md-4 offset-0 order-lg-0 order-2 d-md-block  d-none col-10 col-sm-3 order-sm-2">
            <nav class="navbar navbar-light row navbar-expand-lg d-lg-flex d-md-none d-flex">
              <button
                id="nav-toggle"
                type="button"
                class="ui-navbar-toggler navbar-toggler border-0 p-0 mr-md-0 ml-auto"
                data-toggle="collapse"
                data-target=".navbar-28754"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon" />
              </button>
              <div class="collapse navbar-collapse navbar-28754">
                <b-navbar-nav class="site-navigation ml-auto">
                  <b-nav-item>
                    <b-link
                      to="/"
                      class="nav-link nav-il"
                    >
                      HOME
                    </b-link>
                  </b-nav-item>
                  <b-nav-item>
                    <b-link
                      to="/features"
                      class="nav-link nav-il"
                    >
                      FEATURES
                    </b-link>
                  </b-nav-item>
                  <b-nav-item>
                    <b-link
                      to="/pricing"
                      class="nav-link nav-il"
                    >
                      PRICING
                    </b-link>
                  </b-nav-item>
                  <b-nav-item>
                    <b-link
                      to="/events"
                      class="nav-link nav-il"
                    >
                      EVENTS
                    </b-link>
                  </b-nav-item>
                </b-navbar-nav>
              </div>
            </nav>
          </div>
          <div class="col-md-4 col-btn offset-lg-0 order-md-1 order-lg-1 col-lg-3 order-sm-1 col-sm-5 col-3 order-1">
            <b-link
              class="btn btn-info register-btn for-navbar"
              to="/register"
            >
              REGISTRATION
            </b-link>

            <Slide right>
              <b-navbar-nav class="site-navigation ml-auto">
                <b-nav-item>
                  <b-link
                    to="/"
                    class="nav-link"
                  >
                    HOME
                  </b-link>
                </b-nav-item>
                <b-nav-item>
                  <b-link
                    to="/features"
                    class="nav-link"
                  >
                    FEATURES
                  </b-link>
                </b-nav-item>
                <b-nav-item>
                  <b-link
                    to="/pricing"
                    class="nav-link"
                  >
                    PRICING
                  </b-link>
                </b-nav-item>
                <b-nav-item>
                  <b-link
                    to="/events"
                    class="nav-link"
                  >
                    EVENTS
                  </b-link>
                </b-nav-item>
                
                <b-link
                  class="btn btn-info register-btn for-hamburger"
                  to="/register"
                >
                  REGISTRATION
                </b-link>
              </b-navbar-nav>
            </Slide>
          </div>
        </div>
      </div>
    </div>
    <!-- bloc-0 END -->
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
    name: 'Navigation',
    components: {
        Slide
    }
}
</script>

<style>
.bm-menu {
  background: rgba(0,0,0, 1) !important;
  background-color: rgba(0,0,0, 1) !important;
}

.bm-menu ul li {
  padding-left: 50px;
}

.bm-menu li.nav-item a {
  color: #fff !important;
}

.bm-menu li.nav-item a:hover {
  color: #28a745 !important;
}

.bm-burger-bars {
    background: transparent !important;
}

.bm-burger-button {
  top: 7px !important;
  right: 16px !important;
  background: transparent url('/assets/svg/hamburger.svg') no-repeat !important;
  width: 59px !important;
  height: 58px !important;
}

ul li.nav-item.green-nav-button {
  list-style: none;
}

.bm-menu a.for-hamburger {
    position: relative !important;
    top: 25px !important;
}

.bm-menu a.for-hamburger :hover {
  color: #fff !important;
}

@media only screen and (max-width: 576px) {
  a.for-navbar {
    display: none !important;
  }

  .bm-burger-bars {
      background-color: #28a745 !important;
  }
}

.bm-menu .register-btn {
  position: relative;
  left: -50px;
  top: 300px;
}
#bloc-0 .register-btn {
  margin: 0 80px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px !important;
  width: 189px;
  height: 71px;
  font-family: Poppins;
  font-size: 20px !important;
  font-weight: 300;
  background: #0D85A2;
  border-radius: 100px !important;
}
</style>