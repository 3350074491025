<template>
  <div>
    <preloader />
    <header>

      <navigation />

    </header>

    <main>

      <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3">
                    <div class="user-profile">
                        <div class="big-avatar text-center"><img class="img-fluid" src="https://i.pravatar.cc/1000?u=user@pravatar.com"></div>
                        <div class="text-center">
                            <h3 class="fullname">{{ delegate.name }} {{ delegate.surname }}</h3><span class="edit"><strong>EDIT</strong></span></div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9">
                    <div class="col-12">
                        <form class="form-search" @submit.prevent="submit">
                        <div class="search col-12">
                            <h1 class="search-heading">Looking for an event?</h1>
                            <p>Have you registered but can't find your conference or trade show?</p>
                            <div class="col-12"><input type="search" id="keyword" name="keyword" class="search-input" placeholder="Search for Event" v-model="form.keyword"><button class="btn btn-primary btn-search" type="submit" @click="submit"><i class="fa fa-search"></i></button></div>
                            <div class="col-12">
                                <p class="d-xl-flex search-result">{{ result }}</p>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <div v-if="events.length" class="row">
                <div class="col">
                  <div class="block">
                  </div>
                  <div>
                      <h3><strong>All Events</strong></h3>
                  </div>
                </div>
            </div>
            <div v-if="events.length" class="row">
                <div v-for="event in events" 
                      v-bind:key="event" 
                      class="col col-md-4 col-12">
                    <div class="event-card">
                        <div class="container">
                            <div class="row">
                                <div class="col"><img class="card-image" src="/assets/img/africity-event.png"></div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h5 class="text-center" style="color: #000 !important"><strong>{{ event.title }}</strong></h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p>Start Date&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ event.date }}</p>
                                    <p>Start Time&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ event.start_time }} - {{ event.end_time }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center"><button class="btn btn-primary btn-event-join" type="button" @click="join(event.uuid)">Join the Event</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                  <div class="block"></div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                  <div class="block"></div>
                </div>
            </div>
        </div>
    </main>

    <footer>
      <copyright />
    </footer>

    <scrolltotop />

  </div>
</template>

<script>
import axios from 'axios'

import preloader from '@/components/blocks/home/preloader.vue'
import navigation from '@/components/layouts/users/delegates/navigation.vue'
import copyright from '@/components/layouts/users/delegates/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'DelegatesPanel',
  components: {
    preloader,
    navigation,
    copyright,
    scrolltotop
  },
  props: {

  },
  data () {
    return {
      form: {
        keyword: '',
      },
      events: {},
      result: '',
      delegate: {},
    }
  },
  computed: {

  },
  mounted () {
    this.events = this.getEvents();
    this.delegate = JSON.parse(localStorage.getItem('delegate'));
  },
  methods: {
    getEvents: async function(){
      let response = axios.get(process.env.VUE_APP_API_URL + '/events')
                          .then((response) => {
                              // console.log( response.data );
                              this.events = response.data;
                          })
                          .catch( response => {
                              console.error('error' + response);
                          })
      return response.data;
    },
    join: function(uuid){
      this.$router.push({ path: '/users/delegates/sessions/' + uuid });
    },
    submit: async function(){
      let response = axios.post(process.env.VUE_APP_API_URL + '/event/search', this.form)
                          .then((response) => {
                              // console.log( response.data );
                              this.events = response.data;
                          })
                          .catch( response => {
                              console.error('error' + response);
                          })
      return response.data;
    }
  }
}
</script>

<style>
@import url('/assets/css/delegates-styles.css');
@import url('/assets/css/delegates-styles-button.css');
@import url('/assets/css/delegates-styles-clean.css');
@import url('/assets/css/delegates-styles-search.css');

.form-search .search-field {
  min-height: 20vh;
}
</style>