<template>
  <div>
    <preloader />

    <!-- Main container -->
    <div class="page-container">
      <logo />

      <!-- Subscribers Subscribe -->
      <form
        id="subscriber-register-form"
        data-form-type="blocs-form"
        novalidate
        data-success-msg="Your message has been sent."
        data-fail-msg="Sorry it seems that our mail server is not responding, Sorry for the inconvenience!"
        @submit.prevent="submit"
      >
        <div
          id="subscribers-register"
          class="bloc none bg-center l-bloc"
        >
          <div class="container bloc-md-lg">
            <div class="row">
              <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 register-col col-lg-8 offset-lg-2">
                <h6 class="mg-md text-lg-center style-h4 text-md-center text-sm-center text-center">
                  Become a Subscriber
                </h6>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div class="form-group form-space">
                          <label class="form-label">
                            First Name
                          </label>
                          <input
                            id="name"
                            v-model="form.name"
                            name="name"
                            class="form-control field-style"
                            required
                          >
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group form-space">
                          <label class="form-label">
                            Last Name
                          </label>
                          <input
                            id="surname"
                            v-model="form.surname"
                            name="surname"
                            class="form-control field-style"
                            required
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-space">
                      <label class="form-label">
                        Email
                      </label>
                      <input
                        id="email"
                        v-model="form.email"
                        name="email"
                        class="form-control field-style"
                        type="email"
                        data-error-validation-msg="Not a valid email address"
                        required
                      >
                    </div>
                    <div class="form-group form-space">
                      <label class="form-label">
                        Phone Number
                      </label>
                      <input
                        id="telephone"
                        v-model="form.telephone"
                        name="telephone"
                        class="form-control field-style"
                        placeholder="+27 72 345 6789"
                        required
                      >
                    </div>
                    <div class="form-group form-space">
                      <label class="form-label">
                        Company or Organisation (Optional)
                      </label>
                      <input
                        id="company"
                        v-model="form.company"
                        name="company"
                        class="form-control field-style"
                        placeholder=""
                        required
                      >
                    </div>
                    <div class="form-group form-space">
                      <label class="form-label">
                        Job Title
                      </label>
                      <input
                        id="job_title"
                        v-model="form.job_title"
                        name="job_title"
                        class="form-control field-style"
                        placeholder=""
                        required
                      >
                    </div>
                    <label class="form-label">
                      Sector
                    </label>
                    <div class="form-group">
                      <select
                        id="sector"
                        v-model="form.sector"
                        name="sector"
                        class="form-control field-style"
                      >
                        <option
                          disabled
                          value=""
                          selected="selected"
                        >
                          Please select a Sector
                        </option>
                        <option
                          v-for="(sector, value) in sectors"
                          :key="sector"
                          :value="value"
                          :selected="value === 0"
                        >
                          {{ sector }}
                        </option>
                      </select>
                    </div>
                    <div class="form-check">
                      <input
                        id="receive_updates"
                        v-model="form.receive_updates"
                        class="form-check-input"
                        type="checkbox"
                        data-validation-minchecked-message="You must agree to this before we can take your information."
                        data-validation-minchecked-minchecked="1"
                        name="receive_updates"
                        value="1"
                      >
                      <label class="form-check-label label-style">
                        Accept to receive event update from showbay24
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="accept_terms"
                        v-model="form.accept_terms"
                        class="form-check-input"
                        type="checkbox"
                        data-validation-minchecked-message="You must agree to this before we can take your information."
                        data-validation-minchecked-minchecked="1"
                        name="accept_terms"
                        value="1"
                      >
                      <label class="form-check-label label-style">
                        I have read and accepted Terms and Conditions and Privacy Policy
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row voffset">
                  <div class="col">
                    <b-link
                      to="/register"
                      class="a-btn a-block link-homepage"
                    >
                      Back to Registration
                    </b-link>
                  </div>
                  <div class="col">
                    <button
                      class="bloc-button btn btn-d float-lg-right btn-rd account-btn"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- Subscribers Subscribe END -->

      <blank />

      <footerlinks />

      <copyright />

      <scrolltotop />
    </div>
    <!-- Main container END -->
  </div>
</template>

<script>
import axios from 'axios'

import preloader from '@/components/blocks/home/preloader.vue'
import logo from '@/components/blocks/home/logo.vue'
import blank from '@/components/blocks/home/blank.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'SubscribersSubscribe',
  components: {
    preloader,
    logo,
    blank,
    footerlinks,
    copyright,
    scrolltotop
  },
  props: {

  },
  data () {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        telephone: '',
        company: '',
        job_title: '',
        sector: '',
        receive_updates: '',
        accept_terms: '',
      },
      sectors: {},
    }
  },
  computed: {

  },
  mounted () {
    this.sectors = this.getSectors();
  },
  methods: {
    getSectors: async function(){
      let response = axios.get('/sectors')
                          .then((response) => {
                              this.sectors = response.data;
                          })
                          .catch( response => {
                              console.error('error' + response);
                          })
      return response.data;
    },
    submit: async function(){
      let response = axios.post('/subscriber/store', this.form)
                          .then((response) => {
                              if (response.status === 200)
                              {
                                this.$router.push({ path: '/register' });
                              }
                          })
                          .catch( response => {
                              console.error('error' + response);
                          })
      return response.data;
    }
  }
}
</script>

<style scoped>
@import url('/assets/css/style.css');

.account-btn {
	background-color:#0D85A2;
	font-weight:600;
  color: white;
}
.account-btn:hover {
	background-color:#27AE5F;
}

button {
	outline: none!important;
}

.btn-rd {
	border-radius: 40px;
}
</style>
