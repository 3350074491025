import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import axios from 'axios';
import FlashMessage from '@smartweb/vue-flash-message';

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$http = axios;
const token = localStorage.getItem('token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FlashMessage);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');