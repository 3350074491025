<template>
  <div>
    <preloader />
    
    <!-- Main container -->
    <div class="page-container">
      <logo />
      
      <!-- Delegates Login -->
      <div
        id="delegates-register"
        class="bloc l-bloc"
      >
        <div class="container bloc-sm-lg bloc-no-padding">
          <div class="row">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 register-col col-lg-6 offset-lg-3">
              <h6 class="mg-md text-lg-center style-h4 text-md-center text-sm-center text-center">
                Login as Delegate
              </h6>
              <div class="row">
                <div class="col">
                  <form
                    id="delegates-login-form"
                    data-form-type="blocs-form"
                    novalidate
                    data-success-msg="Your message has been sent."
                    data-fail-msg="Sorry it seems that our mail server is not responding, Sorry for the inconvenience!"
                    
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group form-space">
                          <label class="form-label">
                            Email
                          </label>
                          <input
                            id="email"
                            name="email"
                            class="form-control field-style"
                            required
                            type="email"
                            data-error-validation-msg="Not a valid email address"
                            v-model="form.email"
                          >
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group form-space">
                          <label class="form-label">
                            Password
                          </label>
                          <input
                            id="password"
                            name="password"
                            class="form-control field-style"
                            required
                            type="password"
                            v-model="form.password"
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row voffset">
                <div class="col">
                  <b-link
                    to="/register"
                    class="a-btn a-block link-homepage"
                  >
                    Back to Registration
                  </b-link>
                </div>
                <div class="col">
                  <button
                    class="bloc-button btn btn-d float-lg-right btn-rd account-btn float-sm-right"
                    type="submit"
                     v-on:click="submit"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Delegates Login END -->

      <blank />

      <footerlinks />

      <copyright />

      <FlashMessage :position="'right top'"/>

      <scrolltotop />
    </div>
    <!-- Main container END -->
  </div>
</template>

<script>
import axios from 'axios'

import preloader from '@/components/blocks/home/preloader.vue'
import logo from '@/components/blocks/home/logo.vue'
import blank from '@/components/blocks/home/blank.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

const $ = require('jquery');
window.$ = $;

export default {
  name: 'DelegatesLogin',
  components: {
    preloader,
    logo,
    blank,
    footerlinks,
    copyright,
    scrolltotop
  },
  props: {

  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
    }
  },
  computed: {

  },
  mounted () {
    //
  },
  methods: {
    submit: async function(){
      console.log(process.env.VUE_APP_API_URL)
      let response = axios.post(process.env.VUE_APP_API_URL + '/delegates/login', this.form)
                          .then((response) => {
                              let authenticated = (response.status === 200) && (response.data.token);
                              if (authenticated)
                              {
                                console.log(response.data)
                                const token = response.data.token;
                                const delegate = response.data.delegate;
                                localStorage.setItem('token', token);
                                localStorage.setItem('delegate', JSON.stringify(delegate));
                                this.$router.push({ path: '/users/delegates/panel' });
                              } else {
                                this.flashMessage.setStrategy('single');
                                this.flashMessage.warning({
                                  title: 'An unknown issue has occurred',
                                  message: response.data.message
                                });
                              }
                          })
                          .catch( error => {
                            let err = '';
                            let message = error.response.data.message;
                            console.log(Object.keys(message).length)
                            if (Object.keys(message).length){
                              $.each(message, function(index, value){
                                $.each(value, function(i, v){
                                  err += v + "\r\n";
                                });
                              });
                            } else {
                              err = message;
                              console.warn(err);
                            }
                            
                            this.flashMessage.setStrategy('single');
                            this.flashMessage.error({
                              title: 'An error occurred',
                              message: err
                            });

                          })
      return response.data;
    }
  }
}
</script>

<style scoped>
@import url('/assets/css/style.css');

.account-btn {
	background-color:#0D85A2;
	font-weight:600;
  color: white;
}
.account-btn:hover {
	background-color:#27AE5F;
}

button {
	outline: none!important;
}

.btn-rd {
	border-radius: 40px;
}
</style>

