<template>
  <div>
    <preloader />

    <!-- Main container -->
    <div class="page-container">
      <navigation />

      <carousel />

      <features />

      <lowerfirst />

      <lowersecond />

      <partners />

      <subscriber />

      <scrolltotop />

      <footerlinks />

      <copyright />
    </div>
    <!-- Main container END -->
  </div>
</template>

<script>
import preloader from '@/components/blocks/home/preloader.vue'
import navigation from '@/components/layouts/home/navigation.vue'
import carousel from '@/components/blocks/home/carousel.vue'
import features from '@/components/blocks/home/features.vue'
import lowerfirst from '@/components/blocks/home/lowerfirst.vue'
import lowersecond from '@/components/blocks/home/lowersecond.vue'
import partners from '@/components/blocks/home/partners.vue'
import subscriber from '@/components/blocks/home/subscriber.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'Index',
  components: {
    preloader,
    navigation,
    carousel,
    features,
    lowerfirst,
    lowersecond,
    partners,
    subscriber,
    footerlinks,
    copyright,
    scrolltotop
  },
}
</script>

<style>
.subscriber {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -30px;
}

.btn-rd {
    border-radius: 40px;
}

/* = Colour
-------------------------------------------------------------- */


/* Background colour styles */

.bgc-medium-sea-green {
    background-color: #27AE60 !important;
}

.bgc-cerulean {
    background-color: #0D85A2 !important;
}


/* Icon colour styles */

.icon-medium-sea-green {
    color: #27AE60!important;
    border-color: #27AE60!important;
}

.carousel-slider {
    z-index: 9999
}

#carousel-1 {
    position: relative;
    top: 0px !important;
}
</style>