<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1650"
      img-height="812"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <!--<b-carousel-slide
        caption="First slide"
        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
        img-src="https://loremflickr.com/g/1650/812/speaker"
      ></b-carousel-slide>-->

      <!-- Slides with custom text -->
      <b-carousel-slide img-src="/assets/images/homepage-carousel-slide-001.png">
        <div
          id="slide-1"
          class="content text-left"
        >
          <h1>Connecting Global Businesses</h1>
          <p>
            <span><strong>Showbay24</strong> is an end-to-end event management platform that focuses on a collaborative approach to building your online or hybrid event ensuring the best chances of success.</span>
          </p>
          <a
            class="btn btn-success carousel-register-btn"
            href="/register"
          >
            REGISTRATION
          </a>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-src="/assets/images/homepage-carousel-slide-002.png">
        <div
          id="slide-2"
          class="content text-left"
        >
          <h1>Virtual Events Management</h1>
          <p>
            Showbay24 offers an opportunity to businesses and organisations to host high quality - <strong>Hyper Experience</strong> Virtual Events. Our  eventing solutions includes LIVE Interactive audiences who can tune in remotely from anywhere in the World.
          </p>
          <a
            class="btn btn-success carousel-register-btn"
            href="/register"
          >
            REGISTRATION
          </a>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-src="/assets/images/homepage-carousel-slide-003.png">
        <div
          id="slide-3"
          class="content text-left"
        >
          <h1>Virtual Collaborative Sessions</h1>
          <p>
            Live Conferencing. Live Webinars. Internal Organisational Hybrid Events. External Organisational Hybrid Events.
          </p>
          <a
            class="btn btn-success carousel-register-btn"
            href="/register"
          >
            REGISTRATION
          </a>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-src="/assets/images/homepage-carousel-slide-004.png">
        <div id="slide-4" class="content text-left">
          <h1>International Keynote Speakers</h1>
          <p>
            Our emphasis is creating <strong>Hyper Experiences</strong> with our suite of technology products that enables us to give our clients what they need without the gold plating.
          </p>
          <a class="btn btn-success carousel-register-btn" href="/register">
            REGISTRATION
          </a>
        </div>
      </b-carousel-slide>


      <!-- Slides with image only -->
      <!--<b-carousel-slide img-src="https://loremflickr.com/g/1650/812/conference"></b-carousel-slide>-->

      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!--<b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1650"
            height="812"
            src="/assets/images/homepage-carousel-slide-001.png"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>-->
    </b-carousel>
  </div>
</template>

<script>
import Vue from 'vue'

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)

export default {
    name: 'Carousel',
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
#carousel-1 {
    position: relative;
    top: -178px;
    min-width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    left: -15px;
}

#carousel-1 #slide-1 {
  width: 640px;
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 200px;
}

#carousel-1 #slide-2 {
  width: 640px;
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 160px;
}

#carousel-1 #slide-3 {
  width: 640px;
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 100px;
}

#carousel-1 #slide-4 {
  width: 640px;
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 200px;
}

#carousel-1 h1 {
    font-family: Poppins;
    font-size: 3.2vw;
    font-weight: 900;
}

#carousel-1 p {
  font-family: Poppins;
  font-size: 1.2vw;
  font-weight: 500;
}

.carousel-register-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px !important;
  width: 189px;
  height: 71px;
  font-family: Poppins;
  font-size: 20px !important;
  font-weight: 300;
  background: #28a745;
  border-radius: 100px !important;
}

@media only screen and (min-width:360px) and (max-width: 1024px){
  #carousel-1 #slide-1 {
    padding-bottom: 1.5vh;
    min-width: 109vw;
  }

  #carousel-1 #slide-2 {
    padding-bottom: 1.5vh;
    min-width: 109vw;
  }

  #carousel-1 #slide-3 {
    padding-bottom: 1.5vh;
    min-width: 109vw;
  }

  #carousel-1 #slide-4 {
    padding-bottom: 1.5vh;
    min-width: 109vw;
  }
}


</style>
