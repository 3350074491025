<template>
<div>
<div class="row">
  <div class="col">  <!-- Start: Navigation Clean -->
  <nav
    class="navbar navbar-light navbar-expand-md navigation-clean navigation-delegate"
  >
    <div class="container">
      <a class="navbar-brand" href="#" style="max-width: 160px;">
          EVENT ORGANISER LOGO
        <!--<img src="/assets/img/logo.png" style="width: 100%;">-->
      </a>
      <button
        data-toggle="collapse"
        class="navbar-toggler"
        data-target="#navcol-1"
      >
        <span class="sr-only">Toggle navigation</span
        ><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="nav navbar-nav ml-auto">
          <li class="nav-item" role="presentation">
            <b-link class="nav-link active" to="/users/delegates/panel"><strong>HOME</strong></b-link>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" href="/chats"><strong>CHATS</strong></a>
          </li>
          <li class="nav-item" role="presentation">
            <b-link  class="nav-link" to="/notifications"><strong>NOTIFICATIONS</strong></b-link>
          </li>
          <li class="nav-item" role="presentation">
            <div class="nav-item dropdown show" style="max-width: 120px;">
              <b-link
                class="dropdown-toggle active"
                data-toggle="dropdown"
                aria-expanded="true"
                to="#"
                ><img
                  class="avatar"
                  src="https://i.pravatar.cc/1000?u=user@pravatar.com"
                /></b-link>
              <div class="dropdown-menu show" role="menu">
                <b-link class="dropdown-item" role="presentation" to="/users/delegates/profile"
                  >Profiles</b-link>
                <b-link class="dropdown-item" role="presentation" to="/users/delegates/settings"
                  >Settings</b-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End: Navigation Clean -->
  </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="container">
            <div class="block"></div>
          </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  }
}
</script>

<style>
@import url('/assets/css/delegates-styles.css');
@import url('/assets/css/delegates-styles-button.css');
@import url('/assets/css/delegates-styles-clean.css');
@import url('/assets/css/delegates-styles-search.css');

img.avatar {
  width: 30%;
  border-radius: 50px;
}

.block {
  height: 120px;
}
</style>