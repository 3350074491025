<template>
  <div>
    <preloader />

    <!-- Main container -->
    <div class="page-container">
      <logo />

      <!-- register -->
      <div
        id="register"
        class="bloc l-bloc"
      >
        <div class="container bloc-sm-lg bloc-no-padding">
          <div class="row">
            <div class="col-sm-10 offset-sm-1 register-col offset-md-1 col-md-10 offset-lg-3 col-lg-7">
              <h6 class="mg-md text-lg-center style-h4 text-md-center text-sm-center text-center">
                Register or Login as
              </h6>
              <div class="row">
                <div class="col">
                  <div class="register-as">
                    <div class="text-center">
                      <span class="et-icon-profile-male icon-lg icon-reg" />
                    </div>
                    <h3 class="mg-md text-lg-center h3-style text-center">
                      <b-link
                        class="link-register"
                        to="/users/delegates/register"
                      >
                        Delegates
                      </b-link><br>
                    </h3>
                  </div>
                  <h3 class="mg-md text-link text-lg-center text-center">
                    <span class="ion ion-ios-locked">&nbsp;<b-link to="/users/delegates/login">Login as Delegates</b-link><br></span>
                  </h3>
                </div>
                <div class="col">
                  <div class="register-as">
                    <div class="text-center">
                      <span class="et-icon-profile-male icon-lg icon-reg" />
                    </div>
                    <h3 class="mg-md text-lg-center h3-style text-center">
                      <b-link
                        class="link-register"
                        to="/users/organisers/register"
                      >
                        Event Organiser
                      </b-link><br>
                    </h3>
                  </div>
                  <h3 class="mg-md text-link text-center text-lg-center">
                    <span class="ion ion-ios-locked" />&nbsp;<b-link to="/users/organisers/login">
                      Login as Organiser
                    </b-link><br>
                  </h3>
                </div>
              </div>
              <div class="row voffset">
                <div class="col">
                  <b-link
                    to="/"
                    class="a-btn a-block link-homepage"
                  >
                    Back to the homepage
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- register END -->

      <blank />

      <footerlinks />

      <copyright />

      <scrolltotop />
    </div>
    <!-- Main container END -->
  </div>
</template>

<script>
import preloader from '@/components/blocks/home/preloader.vue'
import logo from '@/components/blocks/home/logo.vue'
import blank from '@/components/blocks/home/blank.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'Register',
  components: {
    preloader,
    logo,
    blank,
    footerlinks,
    copyright,
    scrolltotop
  },
}
</script>
