<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data: () => ({

  }),
  mounted(){
    // Loading JavaScript Libraries
    // jQuery
    const $ = require('jquery');
    window.$ = $;

    // LazySizes
    let LazySizesScript = document.createElement('script')
    LazySizesScript.setAttribute('src', '/assets/js/lazysizes.min.js')
    document.head.appendChild(LazySizesScript)

  },
  methods: {

  }
}
</script>
<style>
/* @import url('/assets/css/boostrap.min.css'); */
@import url('/assets/css/style.css');
@import url('/assets/css/animate.css');
@import url('/assets/css/et-line.min.css');
@import url('/assets/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap&subset=latin,latin-ext');
.no-padding {
    padding: 0px !important;
}

.no-margin {
    margin: 0px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 0 0 0;
  overflow: hidden;
}
</style>
