import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/home/index.vue';
import Features from '@/views/home/pages/features.vue';
import Pricing from '@/views/home/pages/pricing.vue';
import Events from '@/views/home/pages/events.vue';
import Register from '@/views/home/pages/register.vue';
import ContactUs from '@/views/home/pages/contact-us.vue';

import SubscribersSubscribe from '@/views/home/users/subscribers/subscribe.vue';

// Delegates
import DelegatesRegister from '@/views/home/users/delegates/register.vue';
import DelegatesLogin from '@/views/home/users/delegates/login.vue';
import DelegatesPanel from '@/views/home/users/delegates/panel.vue';
import DelegatesSessions from '@/views/home/users/delegates/sessions.vue';

// Organisers
import OrganisersRegister from '@/views/home/users/organisers/register.vue';
import OrganisersLogin from '@/views/home/users/organisers/login.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'home-index',
        component: Home
    },
    {
        path: '/features',
        name: 'home-features',
        component: Features
    },
    {
        path: '/pricing',
        name: 'home-pricing',
        component: Pricing
    },
    {
        path: '/events',
        name: 'home-events',
        component: Events
    },
    {
        path: '/register',
        name: 'home-register',
        component: Register
    },
    {
        path: '/users/subscribers/subscribe',
        name: 'users-subscribers-subscribe',
        component: SubscribersSubscribe
    },
    {
        path: '/users/delegates/register',
        name: 'users-delegates-register',
        component: DelegatesRegister
    },
    {
        path: '/users/delegates/login',
        name: 'users-delegates-login',
        component: DelegatesLogin
    },
    {
        path: '/users/delegates/panel',
        name: 'users-delegates-panel',
        component: DelegatesPanel
    },
    {
        path: '/users/delegates/sessions',
        name: 'users-delegates-sessions',
        component: DelegatesSessions
    },
    {
        path: '/users/organisers/register',
        name: 'users-organisers-register',
        component: OrganisersRegister
    },
    {
        path: '/users/organisers/login',
        name: 'users-organisers-login',
        component: OrganisersLogin
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    }
];

const router = new VueRouter({
    routes
});

export default router;