<template>
    <div>
        <preloader />

        <!-- Main container -->
        <div class="page-container">
        <navigation />

        <smartheader title="Upcoming Events" />
        
        <!-- bloc-23 -->
        <div class="bloc l-bloc" id="bloc-23">
            <div class="container bloc-lg">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-body"><img src="/assets/img/lazyload-ph.png" data-src="img/afrcity-event.jpg" class="img-fluid mx-auto d-block lazyload" alt="afrcity event" />
                                <h6 class="mg-md heading-6"><a class="heading-6" href="event-details.html">Developing
                                        Africa in Technology</a><br></h6>
                                <p><span class="span-style">Start Date:</span> 26 - 30 April 2022</p>
                                <p><span class="span-style">Start Time:</span> 8:00 - 13:00</p><a href="join-event.html" class="btn btn-d btn-lg btn-block btn-rd account-btn but-green">Join Event</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-body"><img src="/assets/img/lazyload-ph.png" data-src="img/afrcity-event.jpg" class="img-fluid mx-auto d-block lazyload" alt="afrcity event" />
                                <h6 class="mg-md heading-6"><a class="heading-6" href="event-details.html">Developing
                                        Africa in Technology</a><br></h6>
                                <p><span class="span-style">Start Date:</span> 26 - 30 April 2022</p>
                                <p><span class="span-style">Start Time:</span> 8:00 - 13:00</p><a href="join-event.html" class="btn btn-d btn-lg btn-block btn-rd account-btn but-green">Join Event</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-body"><img src="/assets/img/lazyload-ph.png" data-src="img/afrcity-event.jpg" class="img-fluid mx-auto d-block lazyload" alt="afrcity event" />
                                <h6 class="mg-md heading-6"><a class="heading-6" href="event-details.html">Developing
                                        Africa in Technology</a><br></h6>
                                <p><span class="span-style">Start Date:</span> 26 - 30 April 2022</p>
                                <p><span class="span-style">Start Time:</span> 8:00 - 13:00</p><a href="join-event.html" class="btn btn-d btn-lg btn-block btn-rd account-btn but-green">Join Event</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- bloc-23 END -->

        <blank />

        <footerlinks />

        <copyright />

        <scrolltotop />
        </div>
        <!-- Main container END -->
    </div>
</template>

<script>
import preloader from '@/components/blocks/home/preloader.vue'
import navigation from '@/components/layouts/home/navigation.vue'
import smartheader from '@/components/blocks/home/smartheader.vue'
import blank from '@/components/blocks/home/blank.vue'
import footerlinks from '@/components/layouts/home/footerlinks.vue'
import copyright from '@/components/layouts/home/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'ContactUs',
  components: {
    preloader,
    navigation,
    smartheader,
    blank,
    footerlinks,
    copyright,
    scrolltotop
  },
}
</script>

<style scoped>

.account-btn {
  background-color: #0D85A2;
  font-weight: 600;
  color: #fff;
}

.account-btn:hover {
  background-color: #27AE5F;
}
</style>