<template>
  <div>
    <!-- smart header -->
    <div
      id="smart-header"
      class="bloc bg-DE-Image-Virtual-Event-2 l-bloc"
    >
      <div class="container bloc-no-padding-lg bloc-no-padding">
        <div class="row">
          <div class="col">
            <div class="space-bread">
              <h3 class="mg-md top-heading">
                {{ title }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- smart header END -->
  </div>
</template>

<script>
export default {
    name: 'SmartHeader',
    props: {
      title: String
    },
    data(){
        return {
          
        }
    },
    computed: {
        
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
