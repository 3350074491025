<template>
  <div>
    <preloader />
    <header>

      <navigation />

    </header>

    <main>

      <div class="container">
            <subnavigation />
            <div v-if="sessions.length" class="row">
                <div class="col">
                  <div class="block">
                  </div>
                  <div>
                      <h3><strong>All Sessions</strong></h3>
                  </div>
                </div>
            </div>
            <div v-if="sessions.length" class="row">
                <div v-for="session in sessions" 
                      v-bind:key="session" 
                      class="col col-md-4 col-12">
                    <div class="event-card">
                        <div class="container">
                            <div class="row">
                                <div class="col"><img class="card-image" src="/assets/img/africity-event.png"></div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h5 class="text-center" style="color: #000 !important"><strong>{{ session.title }}</strong></h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p>Start Date&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ session.date }}</p>
                                    <p>Start Time&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ session.start_time }} - {{ session.end_time }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center"><button class="btn btn-primary btn-event-join" type="button">View Session</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                  <div class="block"></div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                  <div class="block"></div>
                </div>
            </div>
        </div>
    </main>

    <footer>
      <copyright />
    </footer>

    <scrolltotop />

  </div>
</template>

<script>
import axios from 'axios'

import preloader from '@/components/blocks/home/preloader.vue'
import navigation from '@/components/layouts/users/delegates/navigation.vue'
import subnavigation from '@/components/layouts/users/delegates/subnavigation.vue'
import copyright from '@/components/layouts/users/delegates/copyright.vue'
import scrolltotop from '@/components/buttons/home/scrolltotop.vue'

export default {
  name: 'DelegatesSession',
  components: {
    preloader,
    navigation,
    subnavigation,
    copyright,
    scrolltotop
  },
  props: {

  },
  data () {
    return {
      form: {
        keyword: '',
      },
      events: {},
      result: '',
    }
  },
  computed: {

  },
  mounted () {
    this.sessions = this.getSessions();
  },
  methods: {
    getSessions: async function(){
      let response = axios.get(process.env.VUE_APP_API_URL + '/sessions')
                          .then((response) => {
                              // console.log( response.data );
                              this.events = response.data;
                          })
                          .catch( response => {
                              console.error('error' + response);
                          })
      return response.data;
    }
  }
}
</script>

<style>
@import url('/assets/css/delegates-styles.css');
@import url('/assets/css/delegates-styles-button.css');
@import url('/assets/css/delegates-styles-clean.css');
@import url('/assets/css/delegates-styles-search.css');

.form-search .search-field {
  min-height: 20vh;
}
</style>